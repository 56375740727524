import { ReactNode, useState, useEffect } from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";
import { Paragraph } from "./typography";
import { Icon } from "~/components/ui/icons";
import { notEmpty } from "~/utils/misc";
import { useHash } from "~/hooks/common";
import { spTrackWebInteraction } from "~/utils/tracking";
import { Image } from "~/components/ui/image";
import type { ImageProps } from "~/components/ui/image";

export type AccordionItem = {
	iconImage?: ImageProps;
	title: string;
	panel: string | ReactNode;
	id?: string;
	defaultOpen?: boolean;
};

type Props = {
	items: AccordionItem[];
	variant?: "default" | "sidebar";
};

export function Accordion({ items, variant = "default" }: Props) {
	const hash = useHash();

	// Automatically open accordion item if editor defined it as defaultOpen
	function getDefaultValues() {
		return items
			.map((item, index) => (item.defaultOpen ? `item-${index}` : null))
			.filter(notEmpty);
	}

	const [defaultValues, setDefaultValues] =
		useState<string[]>(getDefaultValues);

	// Automatically open accordion item if user clicks an anchor link related to it
	useEffect(() => {
		if (hash) {
			const id = hash.replace("#", "");
			const index = items.findIndex((item) => item.id === id);

			if (index !== -1) {
				setDefaultValues([`item-${index}`]);
			}
		}
	}, [items, hash]);

	function handleToggle(values: string[]) {
		// Find the clicked item by comparing the new values with the previous defaultValues
		const clickedItem =
			values.find((value) => !defaultValues.includes(value)) ||
			defaultValues.find((value) => !values.includes(value));

		if (clickedItem) {
			const index = parseInt(clickedItem.replace("item-", ""), 10);

			spTrackWebInteraction({
				object: "accordion",
				action: defaultValues.includes(clickedItem) ? "close" : "open",
				value: items[index]?.title,
			});
		}

		setDefaultValues(values);
	}

	return (
		<RadixAccordion.Root
			type="multiple"
			value={defaultValues}
			onValueChange={(values: string[]) => handleToggle(values)}
			className="bg-white dark:bg-transparent"
		>
			{items.map((item, index) => {
				const value = `item-${index}`;

				return (
					<RadixAccordion.Item
						key={value}
						id={item.id}
						value={value}
						className="border-stroke border-b"
					>
						<RadixAccordion.Header asChild>
							<RadixAccordion.Trigger className="group flex w-full items-center justify-between gap-5 p-5 text-left">
								<div className="flex items-center gap-3">
									{variant === "sidebar" ? (
										<Paragraph size="body-small">{item.title}</Paragraph>
									) : (
										<>
											{item.iconImage && item.iconImage.src ? (
												<Image className="w-6" {...item.iconImage} />
											) : null}
											<Paragraph fontWeight="font-medium">
												{item.title}
											</Paragraph>
										</>
									)}
								</div>
								<div className="shrink-0">
									<Icon
										name="chevron-down"
										className="transition-transform group-data-[state=open]:-rotate-180"
									/>
								</div>
							</RadixAccordion.Trigger>
						</RadixAccordion.Header>
						<RadixAccordion.Content className="overflow-hidden data-[state=closed]:animate-radixAccordionSlideUp data-[state=open]:animate-radixAccordionSlideDown">
							{typeof item.panel === "string" ? (
								<Paragraph color="secondary" className="px-5 pb-5">
									{item.panel}
								</Paragraph>
							) : (
								<div className="px-5 pb-5">{item.panel}</div>
							)}
						</RadixAccordion.Content>
					</RadixAccordion.Item>
				);
			})}
		</RadixAccordion.Root>
	);
}
